'use client';

import { useCallback, useEffect } from 'react';
// routes
import {
  // usePathname,
  useRouter,
  useSearchParams,
} from 'src/routes/hook';
import { PATH_AFTER_LOGIN } from '@/shared/config-global';
// import { paths } from '@/routes/paths';
//
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  // const pathName = usePathname();
  // const returnPath = pathName === paths.auth.login ? null : pathName;

  const { authenticated } = useAuthContext();

  const check = useCallback(() => {
    if (authenticated) {
      // router.replace(returnTo ?? returnPath ?? PATH_AFTER_LOGIN);
      router.replace(returnTo ?? PATH_AFTER_LOGIN);
    }
  }, [authenticated, returnTo, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
